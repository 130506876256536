import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h4: {
      fontWeight: 600,
      marginBottom: "1.5rem",
    },
    h6: {
      fontWeight: 500,
      marginTop: "1rem",
      marginBottom: "0.5rem",
    },
    body1: {
      fontSize: "1rem",
    },
  },
});

const retailCosts = {
  "Allopurinol 100mg tablet": 0.55,
  "Jardiance 25mg tablet": 575.16,
  "Losartan potassium 50mg tablet": 0.85,
  "Metformin hydrochloride 1000mg tablet": 0.85,
  "Ondansetron 4mg tablet": 4.75,
  "Ozempic 4mg/3ml solution pen injector": 910.71,
  "Rosuvastatin calcium 10mg tablet": 0.55,
};

const insurancePlans = [
    {
      name: "Wellcare Value Script (PDP)",
      details: {
        monthlyPremium: 0.0,
        deductible: 590.0,
        stages: {
            Deductible: {
                tiers: {
                  1: "$0.00",
                  2: "$5.00",
                  3: "100%",
                  4: "100%",
                  5: "100%",
                },
              },    
          initial: {
            tiers: {
              1: "$0.00",
              2: "$5.00",
              3: "25%",
              4: "45%",
              5: "25%",
            },
          },
          catastrophic: {
            tiers: {
              1: "$0",
              2: "$0",
              3: "$0",
              4: "$0",
              5: "$0",
            },
          },
        },
        drugCoverage: {
          "Allopurinol 100mg tablet": 1,
          "Jardiance 25mg tablet": 3,
          "Losartan potassium 50mg tablet": 1,
          "Metformin hydrochloride 1000mg tablet": 1,
          "Ondansetron 4mg tablet": 2,
          "Ozempic 4mg/3ml solution pen injector": 3,
          "Rosuvastatin calcium 10mg tablet": 1,
        },
      },
    },
    {
      name: "Humana Premier Rx Plan (PDP)",
      details: {
        monthlyPremium: 124.0,
        deductible: 0.0,
        stages: {
          initial: {
            tiers: {
              1: "$0.00",
              2: "$4.00",
              3: "$45.00",
              4: "50%",
              5: "33%",
            },
          },
          catastrophic: {
            tiers: {
              1: "$0.00",
              2: "$0.00",
              3: "$0.00",
              4: "$0.00",
              5: "$0.00",
            },
          },
        },
        drugCoverage: {
          "Allopurinol 100mg tablet": 2,
          "Jardiance 25mg tablet": 3,
          "Losartan potassium 50mg tablet": 1,
          "Metformin hydrochloride 1000mg tablet": 1,
          "Ondansetron 4mg tablet": 2,
          "Ozempic 4mg/3ml solution pen injector": 3,
          "Rosuvastatin calcium 10mg tablet": 1,
        },
      },
    },
  ];

const annualOOPThreshold = 2000;

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => `$${value}`,
        },
      },
    },
  };

  
function MedicationCalculator() {
    const [selectedMeds, setSelectedMeds] = useState([]);
    const [selectedInsurancePlan, setSelectedInsurancePlan] = useState(null);
    const [supply, setSupply] = useState("30-day");
    const [results, setResults] = useState([]);
    
  
    const handleInsuranceChange = (e) => {
      const plan = insurancePlans.find((plan) => plan.name === e.target.value);
      setSelectedInsurancePlan(plan);
    };
  
    const calculateCoverageDetails = () => {
        if (!selectedInsurancePlan || selectedMeds.length === 0) return [];
    
        return selectedMeds.map((med) => {
            const tier = selectedInsurancePlan.details.drugCoverage?.[med] || "N/A";
            const retailCost = supply === "90-day" ? retailCosts[med] * 3 : retailCosts[med];
    
            const deductiblePhase =
                selectedInsurancePlan.details.stages?.Deductible?.tiers?.[tier] || "N/A";
    
            const initialCoverageRaw =
                selectedInsurancePlan.details.stages?.initial?.tiers?.[tier] || "N/A";
    
            const catastrophicCoverage =
                selectedInsurancePlan.details.stages?.catastrophic?.tiers?.[tier] || "N/A";
    
            const adjustCostForSupply = (costShare) => {
                if (typeof costShare === "string" && costShare.includes("%")) {
                    // Percentage-based cost
                    const percentage = parseFloat(costShare) / 100;
                    const calculatedCost = retailCost * percentage;
                    return `${costShare} ($${calculatedCost.toFixed(2)})`;
                } else if (typeof costShare === "string" && costShare.includes("$")) {
                    // Fixed dollar cost
                    const parsedCost = parseFloat(costShare.replace("$", ""));
                    const adjustedCost = parsedCost * (supply === "90-day" ? 3 : 1); // Adjust for 90-day supply
                    return `$${adjustedCost.toFixed(2)}`;
                }
                return costShare; // Return costShare as-is if no valid format
            };
    
            return {
                medication: med,
                tier,
                retailCost: `$${retailCost.toFixed(2)}`,
                deductiblePhase: adjustCostForSupply(deductiblePhase),
                initialCoverage: adjustCostForSupply(initialCoverageRaw),
                catastrophicCoverage: adjustCostForSupply(catastrophicCoverage),
            };
        });
    };

    const calculateOutOfPocket = (insurancePlan) => {
        if (!insurancePlan || selectedMeds.length === 0) {
            console.log("No insurance plan or no medications selected");
            return [];
        }
    
        console.log("Calculating Out of Pocket...");
        console.log("Insurance Plan:", insurancePlan.name);
        console.log("Selected Medications:", selectedMeds);
    
        const totalMonths = 12;
        const refillInterval = supply === "90-day" ? 3 : 1; // 90-day refill every 3 months
        const deductibleLimit = insurancePlan.details.deductible || 0;
        const catastrophicLimit = 2000; // TrOOP threshold
        let remainingDeductible = deductibleLimit;
        let trueOutOfPocket = 0;
        const monthlyBreakdown = [];
    
        for (let month = 1; month <= totalMonths; month++) {
            let monthlyOutOfPocket = 0;
    
            // Refill drugs only on their refill schedule
            if ((month - 1) % refillInterval === 0) {
                // eslint-disable-next-line no-loop-func
                selectedMeds.forEach((med) => {
                    const tier = insurancePlan.details.drugCoverage?.[med];
                    const retailCost = supply === "90-day" ? retailCosts[med] * 3 : retailCosts[med];
                    const stages = insurancePlan.details.stages;
    
                    console.log(`Processing Month ${month}, Medication: ${med}`);
                    console.log(`Tier: ${tier}, Retail Cost: ${retailCost}`);
    
                    let remainingCost = retailCost;
    
                    // Deductible Stage
                    if (remainingDeductible > 0) {
                        const deductibleApplied = Math.min(remainingCost, remainingDeductible);
                        monthlyOutOfPocket += deductibleApplied;
                        trueOutOfPocket += deductibleApplied;
                        remainingDeductible -= deductibleApplied;
                        remainingCost -= deductibleApplied;
    
                        console.log("Deductible Applied:", deductibleApplied);
                        console.log("Remaining Deductible:", remainingDeductible);
                    }

                    // Initial Coverage Stage
                    if (remainingCost > 0 && trueOutOfPocket < catastrophicLimit) {
                        const initialCoverage = stages.initial.tiers[tier];
                        let costShare = 0;

                        if (typeof initialCoverage === "string" && initialCoverage.includes("%")) {
                            const percentage = parseFloat(initialCoverage) / 100;
                            costShare = remainingCost * percentage;
                        } else if (typeof initialCoverage === "string" && initialCoverage.includes("$")) {
                            costShare = parseFloat(initialCoverage.replace("$", "")) * (supply === "90-day" ? 3 : 1);
                            trueOutOfPocket += remainingCost * 0.25; 
                            console.log(`Full Co-Pay Applied: $${costShare}`);
                        } else {
                            console.error("Unexpected initialCoverage value:", initialCoverage);
                        }

                        // Ensure no invalid or NaN values
                        if (isNaN(costShare) || costShare < 0) {
                            costShare = 0;
                        }

                        const remainingTrOOP = catastrophicLimit - trueOutOfPocket;

                        console.log(`Remaining TrOOP Before Applying CostShare: $${remainingTrOOP}`);
                        console.log(`Initial CostShare Calculated: $${costShare}`);

                        // Adjust costShare for remaining TrOOP
                        if (costShare > remainingTrOOP) {
                            // If costShare exceeds remaining TrOOP, handle the transition
                            costShare = remainingTrOOP;

                            // New Logic: Handle Co-Pay Causing Exceeding Catastrophic Limit
                            if (typeof initialCoverage === "string" && initialCoverage.includes("$")) {
                                // Apply full co-pay if the co-pay exceeds remaining TrOOP
                                costShare = parseFloat(initialCoverage.replace("$", "")) * (supply === "90-day" ? 3 : 1);
                                console.log(`Adjusted to Full Co-Pay CostShare: $${costShare}`);
                            }
                        }

                        console.log(`Adjusted CostShare After TrOOP Check: $${costShare}`);

                        trueOutOfPocket += costShare;
                        monthlyOutOfPocket += costShare;
                        remainingCost -= costShare;

                        console.log(`True Out of Pocket After Applying CostShare: $${trueOutOfPocket}`);
                        console.log(`Remaining Cost After Initial Coverage: $${remainingCost}`);

                        // Ensure no invalid or NaN values
                        if (isNaN(remainingCost) || remainingCost < 0) {
                            remainingCost = 0;
                        }
                    }

                    // Catastrophic Coverage Stage
                    if (remainingCost > 0 && trueOutOfPocket >= catastrophicLimit) {
                        const catastrophicCoverage = stages.catastrophic.tiers[tier];
                        let catastrophicShare = 0;

                        if (typeof catastrophicCoverage === "string" && catastrophicCoverage.includes("%")) {
                            const percentage = parseFloat(catastrophicCoverage) / 100;
                            catastrophicShare = remainingCost * percentage;
                        } else if (typeof catastrophicCoverage === "string" && catastrophicCoverage.includes("$")) {
                            catastrophicShare = parseFloat(catastrophicCoverage.replace("$", "")) * (supply === "90-day" ? 3 : 1);
                        }

                        if (isNaN(catastrophicShare) || catastrophicShare < 0) {
                            catastrophicShare = 0;
                        }

                        monthlyOutOfPocket += catastrophicShare;
                        remainingCost -= catastrophicShare;

                        if (isNaN(remainingCost) || remainingCost < 0) {
                            remainingCost = 0;
                        }

                        console.log("Catastrophic Coverage Applied:", catastrophicShare);
                    }


                });
            }
    
            console.log(`Month ${month}:`);
            console.log("Monthly Out of Pocket:", monthlyOutOfPocket);
            console.log("True Out of Pocket:", trueOutOfPocket);
    
            monthlyBreakdown.push({
                month: `Month ${month}`,
                outOfPocket: monthlyOutOfPocket || 0,
                trueOutOfPocket: Math.min(trueOutOfPocket, catastrophicLimit) || 0,
                monthlyCap: 0, // Initialize explicitly
                m3pBill: 0, // Initialize explicitly
            });
        }
    
        console.log("Final Monthly Breakdown:", monthlyBreakdown);
        return monthlyBreakdown;
    };
    
    
    const calculatePaymentPlan = (monthlyBreakdown, annualOOPThreshold = 2000) => {
    const totalMonths = 12;
    let remainingBalanceDue = 0; // Tracks cumulative Balance in M3P carried over
    let cumulativeTrueOOPPaid = 0; // Tracks cumulative True OOP Paid (before current month)
    const paymentPlan = [];

    monthlyBreakdown.forEach((monthData, index) => {
        const { month, outOfPocket, trueOutOfPocket } = monthData;
        const monthsRemaining = totalMonths - index; // Remaining months in the year
        let monthlyCap = 0; // Maximum cap for the month
        let m3pBill = 0; // M3P bill for this month
        let balanceInM3P = 0; // Updated balance in M3P after this month

        if (index === 0) {
            // First Month Formula
            const amountToReachThreshold = annualOOPThreshold - cumulativeTrueOOPPaid;
            monthlyCap = amountToReachThreshold > 0
                ? amountToReachThreshold / monthsRemaining
                : 0;
        } else {
            // Subsequent Months Formula
            const adjustedRemainingBalance = remainingBalanceDue + outOfPocket;
            monthlyCap = (adjustedRemainingBalance) / monthsRemaining;
        }

        // Calculate M3P bill for the current month
        m3pBill = Math.min(monthlyCap, outOfPocket + remainingBalanceDue / monthsRemaining);

        // Update the balance for M3P
        balanceInM3P = remainingBalanceDue + (outOfPocket - m3pBill);

        // Update cumulative True OOP Paid for the next month
        cumulativeTrueOOPPaid += outOfPocket;

        // Update remaining balance due for the next month
        remainingBalanceDue = balanceInM3P;

        // Add the calculated data to the payment plan
        paymentPlan.push({
            month,
            outOfPocket: outOfPocket.toFixed(2),
            trueOutOfPocket: trueOutOfPocket.toFixed(2),
            monthlyCap: monthlyCap.toFixed(2),
            m3pBill: m3pBill.toFixed(2),
            balanceInM3P: balanceInM3P.toFixed(2),
        });
    });

    return paymentPlan;
};

    
        const handleCalculatePaymentPlan = () => {
            const monthlyBreakdown = calculateOutOfPocket(selectedInsurancePlan);
            const paymentPlan = calculatePaymentPlan(monthlyBreakdown, annualOOPThreshold).map((item, index) => ({
                ...item,
                month: months[index], // Replace month numbers with names
            }));
            setResults(paymentPlan);
        };
      const chartData = {
        labels: results.map((r) => r.month),
        datasets: [
          {
            label: "Out of Pocket (Without M3P)",
            data: results.map((r) => r.outOfPocket || 0),
            borderColor: "red",
            fill: false,
          },
          {
            label: "M3P Bill Amount",
            data: results.map((r) => r.m3pBill || 0),
            borderColor: "green",
            fill: false,
          },
        ],
      };      
  
    return (
        <ThemeProvider theme={theme}>
        <Container maxWidth="lg" sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
          <Typography variant="h4" align="center" gutterBottom>
          Medicare Prescription Payment Plan (M3P) - Educational Tool
          </Typography>
          {/* Disclaimer */}
          <Grid container justifyContent="center" sx={{ marginBottom: "1.5rem" }}>
            <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                sx={{
                padding: "0.75rem",
                backgroundColor: "#f5f5f5",
                border: "1px solid #ccc",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "600px",
                }}
            >
                Disclaimer: This is an educational tool to better understand the Medicare Prescription Payment Plan (M3P) program. It does not reflect the exact price or coverage.

            </Typography>
                <Grid container justifyContent="center" sx={{ marginTop: "0.5rem" }}>
                    <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                    sx={{
                        fontWeight: "bold",
                    }}
                    >
                    If you have any questions, leave your information{" "}
                    <a
                        href="https://forms.gle/7BUpRnct24tTUkKb9"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#1976d2", fontWeight: "bold" }}
                    >
                        here
                    </a>.
                    </Typography>
                </Grid>
                </Grid>



          {/* Medication Selection */}
          <Grid container spacing={3} sx={{ marginBottom: "2rem" }}>
          <Grid item xs={12}>
            <Typography variant="h6">Select Medications:</Typography>
            <TextField
              select
              fullWidth
            //   label="Medications"
              variant="outlined"
              size="small"
              SelectProps={{
                multiple: true,
                value: selectedMeds,
                onChange: (e) => setSelectedMeds(e.target.value),
              }}
            >
              {Object.keys(retailCosts).map((med) => (
                <MenuItem key={med} value={med}>
                  {med}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
  
          {/* Insurance Plan Selection */}
          <Grid item xs={12}>
            <Typography variant="h6">Select Insurance Plan:</Typography>
            <TextField
              select
              fullWidth
            //   label="Insurance Plan"
              variant="outlined"
              size="small"
              value={selectedInsurancePlan?.name || ""}
              onChange={handleInsuranceChange}
            >
              {insurancePlans.map((plan) => (
                <MenuItem key={plan.name} value={plan.name}>
                  {`${plan.name} - Deductible: $${plan.details.deductible}, Monthly Premium: $${plan.details.monthlyPremium}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
  
          {/* Supply Selection */}
          <Grid item xs={12}>
            <Typography variant="h6">Supply:</Typography>
            <TextField
              select
              fullWidth
              value={supply}
              onChange={(e) => setSupply(e.target.value)}
              variant="outlined"
              size="small"
            >
              <MenuItem value="30-day">30-Day Supply</MenuItem>
              <MenuItem value="90-day">90-Day Supply</MenuItem>
            </TextField>
          </Grid>

          {selectedInsurancePlan && selectedMeds.length > 0 && (
            <Grid item xs={12}>
                <Typography variant="h6">Coverage Information:</Typography>
                <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>Medication</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Tier</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Retail Cost</TableCell>
                        {selectedInsurancePlan.details.deductible > 0 && (
                        <TableCell style={{ fontWeight: 'bold' }}>Deductible Phase</TableCell>
                        )}
                        <TableCell style={{ fontWeight: 'bold' }}>Initial Coverage</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Catastrophic Coverage</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {calculateCoverageDetails().map((detail) => (
                        <TableRow key={detail.medication}>
                        <TableCell>{detail.medication}</TableCell>
                        <TableCell>{detail.tier}</TableCell>
                        <TableCell>{detail.retailCost}</TableCell>
                        {selectedInsurancePlan.details.deductible > 0 && (
                            <TableCell>{detail.deductiblePhase}</TableCell>
                        )}
                        <TableCell>{detail.initialCoverage}</TableCell>
                        <TableCell>{detail.catastrophicCoverage}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
            )}
      </Grid>
  
          {/* Calculate Costs Button */}
          <Grid item xs={12}>

            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCalculatePaymentPlan}
            >
                Calculate Costs
            </Button>
          </Grid>
  
          {/* Monthly Cost Breakdown */}
          <Grid item xs={12}>
            <Typography variant="h6">Monthly Cost Breakdown:</Typography>
            <TableContainer component={Paper}>
              <Table>
              <TableHead>
                <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Month</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Out of Pocket</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>True Out of Pocket Sum</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>M3P Monthly Cap</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>M3P Monthly Bill Amount</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Balance in M3P</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {results.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell style={{ fontWeight: 'bold' }}>{row.month}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>${row.outOfPocket}</TableCell>
                        <TableCell>${row.trueOutOfPocket}</TableCell>
                        <TableCell>${row.monthlyCap}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>${row.m3pBill}</TableCell>
                        <TableCell>${row.balanceInM3P}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
  
          {/* Cost Comparison Chart */}
            <Grid item xs={12}>
            <Typography variant="h6">Cost Comparison Chart:</Typography>
            <Line data={chartData} options={chartOptions} />
            </Grid>
      </Container>
      </ThemeProvider>
    );
  }
  
  export default MedicationCalculator;
  
