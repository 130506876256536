import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import Footer from './components/Footer';
import AboutPage from './components/AboutPage';
import MedicationCalculator from './components/MedicationCalculator';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          {/* Home route displaying HeroSection and AboutSection */}
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <AboutSection />
              </>
            }
          />
          {/* About route */}
          <Route path="/about" element={<AboutPage />} />
          {/* Redirect /medication-calculator to /m3p */}
          <Route path="/medication-calculator" element={<Navigate to="/m3p" />} />
          {/* M3P Tool route */}
          <Route path="/m3p" element={<MedicationCalculator />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
