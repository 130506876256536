import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Logo_wordmark_blackbg.svg';

function Header() {
  return (
    <header className="p-8 bg-black text-white flex justify-between items-center relative">
      {/* Empty div for spacing on the left */}
      <div className="flex-1"></div>

      {/* Centered Logo */}
      <div className="flex items-center justify-center flex-1">
        <img src={logo} alt="MedHug Logo" className="h-16" />
      </div>

      {/* Navigation on the right */}
      <nav className="flex-1 flex justify-end">
        <ul className="flex space-x-6">
          <li>
            <Link to="/m3p" className="hover:text-gray-300 text-xl">M3P Tool</Link>
          </li>
          <li>
            <Link to="/about" className="hover:text-gray-300 text-xl">About</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
